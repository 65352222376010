import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { LoadingPage, PageContainer, Seo, Text } from 'components';
import Header from 'components/Header';
import { useRouter } from 'apis/history';
import { useQuery } from 'styles/breakpoints';
import { useFirstVisitDate, usePageView, useQuizData } from 'utils/hooks';
import { AppState } from 'state/types';
import LoadingBar from './components/LoadingBar';
import Checklist from './components/Checklist';
import BlackFridayBanner from '../../../components/BlackFridayBanner';
import { normalizeStates } from 'utils/localization';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 0 1rem;
`;

const ImageContainer = styled.div`
  max-width: 16rem;
`;
const Image = styled.img`
  width: 100%;
`;

const Title = styled(Text).attrs({
  color: 'dark100',
})`
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const Analyzing = styled(Text).attrs({
  type: 'body',
})`
  margin: 1.5rem 0;
  transition: opacity ease-in-out 2s;
  opacity: 1;
`;

const Calculating: FC = () => {
  const [progress, setProgress] = useState<number>(0);

  const data = useQuizData('calculating');
  const { config, user } = useSelector((state: AppState) => state);
  const variant = config?.variant;
  const { countdownDiffInMS } = useFirstVisitDate();

  const { goToResults, goToEmail } = useRouter();

  const { isMobile } = useQuery();

  usePageView({
    client_code: user.code,
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: user.geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    email: user?.user?.email,
  });

  const handleNavigationOnCounterEnd = () => {
    if (variant === 'funnel-email' || variant === 'email-first') {
      return location.search.includes('tst3') ? goToResults() : goToEmail();
    }
    goToResults();
  };

  if (!data) {
    return <LoadingPage />;
  }
  const {
    title,
    progressTitle,
    imageUrl,
    list,
    modalQuestion,
    modalAnswer1,
    modalAnswer2,
    showModal,
  } = data;

  return (
    <>
      <Seo />

      <PageContainer>
        <Header />
        {data?.bfBanner ? (
          <BlackFridayBanner
            countdownDiffInMS={countdownDiffInMS}
            {...data.bfBanner}
          />
        ) : null}
        <ContentContainer>
          <ImageContainer>
            <Image src={imageUrl} />
          </ImageContainer>
          <Title type={isMobile ? 'h1700' : 'h13Rem'}>{title}</Title>
          <LoadingBar progress={progress} />
          <Analyzing>{progressTitle}</Analyzing>
          <Checklist
            list={list}
            onEnd={handleNavigationOnCounterEnd}
            onProgress={progress => setProgress(progress)}
            modalQuestion={modalQuestion}
            modalAnswer1={modalAnswer1}
            modalAnswer2={modalAnswer2}
            showModal={showModal}
          />
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default Calculating;
