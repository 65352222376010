import React, { FC } from 'react';
import styled from 'styled-components';

interface LoadingBarProps {
  progress: number;
}

const Container = styled.div`
  width: 100%;
  height: 0.625rem;
  max-width: 16.25rem;
  background-color: ${({ theme }) => theme.colors.cultured};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 0.0625rem;
`;

const Progress = styled.div<{ width: number }>`
  width: ${({ width }) => (width > 100 ? 100 : width)}%;
  transition: 5s ease;
  background-color: ${({ theme }) => theme.colors.green};
  height: 0.5rem;
  border-radius: 0.25rem;
`;

const LoadingBar: FC<LoadingBarProps> = ({ progress }) => (
  <Container>
    <Progress width={progress} />
  </Container>
);

export default LoadingBar;
